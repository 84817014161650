import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react';

const Layout: FunctionComponent = (): ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          textAlign: 'center',
        }}
      >
        <Outlet />
        <Box paddingTop={5}>
          <a href="mailto:avontuur.events@gmail.com">Contact us</a>
        </Box>
      </Container>
    </Box>
  );
}

export { Layout };
