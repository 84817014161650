import { FormErrors } from '../types/FormErrors';
import { GuestRegistration } from '../types/Guest';
import { LoadingButton } from '@mui/lab';
import { Send, Close } from '@mui/icons-material';
import styled from 'styled-components';
import { Box, Grid, TextField, Typography, Button, IconButton } from '@mui/material';
import React, { Fragment, FunctionComponent, ReactElement, SyntheticEvent } from 'react';

const InputFields = styled(TextField)`
  min-height: 50px;

  input {
    border-radius: 0;
    border: solid 3px #000;
  }

  & fieldset {
    border: none;
  }

  &.MuiTextField-root {
    margin: 0;
    margin-top: 4px; /* Adjust this value to decrease spacing */
    font-size: 0.75rem; /* Adjust font size if needed */
  }
`;

const ErrorRow = styled(Grid)`
  min-height: 60px;
`;

const StandardButtonStyle = `
  box-shadow: none;
  border-radius: 0;
  font-weight: 600;
  padding: 8px 16px; /* Standard padding */
  border: solid 2px #000;
  display: inline-flex; /* Ensure they are inline-flex */
  align-items: center;
  justify-content: center;
  height: 48px; /* Standard height */
`;

const SubmitButtonWithLoader = styled(LoadingButton)`
  ${StandardButtonStyle}
  background-color: #f7ab32;
  color: #000;
  margin-top: 20px;

  &:hover {
    background-color: #c18524;
    box-shadow: none;
  }
`;

const TicketButton = styled.a`
  ${StandardButtonStyle}
  background-color: #fff;
  color: #000;
  text-decoration: none;

  &:hover {
    background-color: #f2f2f2;
    box-shadow: none;
  }
`;

interface GuestFormProps {
  guestInformation: GuestRegistration[];
  formErrors: FormErrors[];
  isFormValid: boolean;
  isLoading: boolean;
  ticketCount: number;
  setTicketCount: (count: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  handleChange: (index: number, input: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setGuestInformation: (info: GuestRegistration[]) => void;
}

const GuestForm: FunctionComponent<GuestFormProps> = ({
  guestInformation,
  formErrors,
  isFormValid,
  isLoading,
  ticketCount,
  setTicketCount,
  nextStep,
  prevStep,
  handleChange,
  setGuestInformation,
}): ReactElement => {
  const moveToNextStep = (e: SyntheticEvent) => {
    e.preventDefault();
    nextStep();
  };

  const addGuest = () => {
    if (ticketCount < 5) {
      setTicketCount(ticketCount + 1);
    }
  };

  const deleteGuest = (index: number) => {
    const updatedGuestInformation = guestInformation.filter((_, i) => i !== index);
    setGuestInformation(updatedGuestInformation);
    setTicketCount(ticketCount - 1);
  };

  return (
    <Fragment>
      <Typography component="h2" variant="h5">
        Sign up
      </Typography>
      <Box component="form" noValidate sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          {Array.from({ length: ticketCount }).map((_, index) => (
            <Fragment key={index}>
              <Grid item xs={12} sm={5}>
                <InputFields
                  autoComplete="given-name"
                  hiddenLabel
                  name={`firstName${index}`}
                  placeholder="First Name"
                  required
                  fullWidth
                  id={`firstName${index}`}
                  value={guestInformation[index]?.firstname || ''}
                  error={formErrors[index]?.firstnameError}
                  helperText={formErrors[index]?.firstnameError && 'Please enter a valid firstname'}
                  onChange={handleChange(index, 'firstname')}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <InputFields
                  required
                  fullWidth
                  hiddenLabel
                  id={`lastName${index}`}
                  name={`lastName${index}`}
                  placeholder="Last Name"
                  autoComplete="family-name"
                  value={guestInformation[index]?.lastname || ''}
                  error={formErrors[index]?.lastnameError}
                  helperText={formErrors[index]?.lastnameError && 'Please enter a valid lastname'}
                  onChange={handleChange(index, 'lastname')}
                />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {ticketCount > 1 && (
                  <IconButton onClick={() => deleteGuest(index)}>
                    <Close />
                  </IconButton>
                )}
              </Grid>
              <ErrorRow item xs={12}>
                {formErrors[index]?.existingGuestError && (
                  <Typography component="p" color="red" sx={{ mt: 0.5 }}>
                    This guest is already registered!
                  </Typography>
                )}
              </ErrorRow>
            </Fragment>
          ))}

          <Grid item xs={12}>
            <Button onClick={addGuest} disabled={ticketCount >= 5}>
              Add Guest
            </Button>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <TicketButton href="https://tikkio.com/t/5000e0e0a3fa2ad08fc654f253bd9e5b">
                  Signed up? Get ticket here
                </TicketButton>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                {isFormValid && (
                  <SubmitButtonWithLoader
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disableRipple={true}
                    onClick={moveToNextStep}
                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={<Send />}
                  >
                    Continue
                  </SubmitButtonWithLoader>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export { GuestForm };
