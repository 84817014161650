import { isAuthorized } from './utils/isAuthorized';
import { NoMatch } from './NoMatch';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react';
import { Booking } from './components/Booking';

const Headline = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;

  @media (min-width: 600px) {
    font-size: 6rem;
  }
`;

const Logo = styled.img`
  min-width: 200px;
`;

const Stage = styled(Box)`
  width: 100%;
  background-color: #fff;
  padding: 40px;
  border: solid 5px #000;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  @media (min-width: 640px) {
    width: 600px;
  }
`;

const Home: FunctionComponent = (): ReactElement => {
  const [search, ] = useSearchParams();
  const secretParameter = search.get('secret');

  if (!secretParameter) {
    return <NoMatch />
  }

  if (!isAuthorized(secretParameter)) {
    return <NoMatch />
  }

  return (
    <Stage component="section" maxWidth="sm">
      <Headline
        variant="h1"
        align="center"
        gutterBottom
        textTransform='uppercase'
      >
        <Logo src={ `${process.env.PUBLIC_URL}avontuur_logo.svg` } alt="Avontuur Events" />
      </Headline>
      <Booking />
    </Stage>
  );
};

export { Home };