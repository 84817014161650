import { FormButton } from './FormButton';
import { Grid } from '@mui/material';
import { South as SouthIcon } from '@mui/icons-material';
import React, { FunctionComponent, ReactElement, SyntheticEvent } from 'react';
import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const StartEvent = styled(FormButton)`
  font-size: 1.5em;
`;

const ArrowSouthIcon = styled(SouthIcon)`
  margin-bottom: 50px;
  font-size: 5em;

  animation: ${bounce} 2s infinite;
`;

interface IntroductionProps {
  nextStep: () => void;
}

const Introduction: FunctionComponent<IntroductionProps> = ({ nextStep }): ReactElement => {
  const moveToNextStep = (e: SyntheticEvent) => {
    e.preventDefault();

    nextStep();
  }

  return (
    <Grid
      container
      spacing={ 2 }
    >
      <Grid
        item xs={12}
        sx={{ mt: 10 }}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <ArrowSouthIcon fontSize='large' />
        <StartEvent
          type="button"
          variant="contained"
          onClick={ moveToNextStep }
        >
            Be part of the event!
        </StartEvent>
      </Grid>
    </Grid>
  );
};

export { Introduction };