import { Delete } from '@mui/icons-material';
import { Guest } from '../types/Guest';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FunctionComponent, ReactElement } from 'react'

interface PrintTableProps {
  guests: Guest[];
  onDelete: (index: number) => void;
}

const GuestTable: FunctionComponent<PrintTableProps> = ({ guests, onDelete }): ReactElement => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { guests.map(( { _id, firstname, lastname }, index ) => (
            <TableRow
              key={_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                { `${firstname} ${lastname}` }
              </TableCell>
              <TableCell align="right" className='hide-on-print'>
              <IconButton aria-label="delete" onClick={ () => onDelete(index) }>
                <Delete />
              </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export { GuestTable };