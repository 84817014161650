import { Button } from '@mui/material';
import { Guest } from '../types/Guest';
import { ImportExport } from '@mui/icons-material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import React, { FunctionComponent, ReactElement } from 'react';

interface ExportToExcelButtonProps {
  guests: Guest[];
}

const ExportToExcelButton: FunctionComponent<ExportToExcelButtonProps> = ({ guests }): ReactElement => {
  const exportToExcel = (): void => {
    const data = guests.map(({firstname, lastname}) => [ firstname, lastname ]);

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Guests');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(excelData, 'guests.xlsx');
  };

  return (
    <Button variant="outlined" onClick={ exportToExcel } startIcon={ <ImportExport /> } >
      Excel
    </Button>
  );
};

export { ExportToExcelButton };
