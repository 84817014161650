import Api from '../api';
import { FormErrors } from '../types/FormErrors';
import { GuestRegistration } from '../types/Guest';
import { GuestForm } from './GuestForm';
import { Introduction } from './Introduction';
import { Success } from './Success';
import React, { ChangeEvent, FunctionComponent, ReactElement, useEffect, useState } from 'react';

interface StepState {
  step: number;
}

const Booking: FunctionComponent = (): ReactElement => {
  const [stepState, updateStepState] = useState<StepState>({ step: 1 });
  const [ticketCount, setTicketCount] = useState<number>(1);
  const [guestInformation, updateGuestInformation] = useState<GuestRegistration[]>(
    Array(ticketCount).fill({ firstname: '', lastname: '' })
  );
  const [formErrors, setFormErrors] = useState<FormErrors[]>(
    Array(ticketCount).fill({ firstnameError: false, lastnameError: false, existingGuestError: false })
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Proceed to next step
  const nextStep = (): void => {
    updateStepState({ ...stepState, step: stepState.step + 1 });
  };

  // Go back to prev step
  const prevStep = (): void => {
    updateStepState({ ...stepState, step: stepState.step - 1 });
  };

  const handleChange = (index: number, input: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newGuestInformation = [...guestInformation];
    const newFormErrors = [...formErrors];

    newFormErrors[index][`${input}Error`] = e.target.value === '' || e.target.value.length < 2;
    newGuestInformation[index][input] = e.target.value;

    setFormErrors(newFormErrors);
    updateGuestInformation(newGuestInformation);
  };

  const submitForm = async (): Promise<void> => {
    setIsLoading(true);

    // Collect all guests' information
    const guests = guestInformation.slice(0, ticketCount);

    try {
      const newGuestResponse = await Api.addMultipleNewGuests(guests);

      if (newGuestResponse) {
        setIsLoading(false);

        updateStepState({ ...stepState, step: stepState.step + 1 });

        window.location.href = process.env.REACT_APP_TICKET_URL!;
      }
    } catch (error: any) {
      setIsLoading(false);

      if (error.response && error.response.data) {
        // Extract and map the error messages
        const serverErrors = error.response.data;
        const newFormErrors = guests.map((guest, index) => ({
          firstnameError: false,
          lastnameError: false,
          existingGuestError: serverErrors.existingGuests.some(
            (existingGuest: GuestRegistration) =>
              existingGuest.firstname === guest.firstname &&
              existingGuest.lastname === guest.lastname
          )
        }));
        setFormErrors(newFormErrors);
      }
    }
  };

  useEffect(() => {
    // Adjust guestInformation and formErrors arrays when ticketCount changes
    updateGuestInformation((prev) =>
      Array(ticketCount)
        .fill(null)
        .map((_, i) => prev[i] || { firstname: '', lastname: '' })
    );
    setFormErrors((prev) =>
      Array(ticketCount)
        .fill(null)
        .map((_, i) => prev[i] || { firstnameError: false, lastnameError: false, existingGuestError: false })
    );
  }, [ticketCount]);

  useEffect(() => {
    // Validate the form for all guests
    const isValid = guestInformation.slice(0, ticketCount).every((guest) =>
      guest.firstname.trim() !== '' && guest.lastname.trim() !== ''
    );
    setIsFormValid(isValid);
  }, [guestInformation, ticketCount]);

  const { step } = stepState;

  switch (step) {
    case 1:
      return (
        <Introduction
          nextStep={nextStep}
        />
      );
    case 2:
      return (
        <GuestForm
          nextStep={submitForm}
          prevStep={prevStep}
          handleChange={handleChange}
          guestInformation={guestInformation}
          formErrors={formErrors}
          isLoading={isLoading}
          isFormValid={isFormValid}
          ticketCount={ticketCount}
          setTicketCount={setTicketCount}
          setGuestInformation={updateGuestInformation}
        />
      );
    case 3:
      return <Success prevStep={prevStep} />;
    default:
      return <div>Something went wrong!</div>;
  }
};

export { Booking };
