import api from './api';
import { Guest } from './types/Guest';
import styled from 'styled-components';
import React, { useState, useEffect, FunctionComponent, ReactElement } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { FormButton } from './components/FormButton';
import { GuestListing } from './components/GuestListing';

const Headline = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;

  @media (min-width: 600px) {
    font-size: 6rem;
  }
`;

const Container = styled.div`
  background-color: #fff;
  padding: 25px;
  width: 100%;
  text-align: left;
`;

const InputFields = styled(TextField)`
  min-height: 85px;
  margin-right: 5px;

  input {
    border-radius: 0;
    border: solid 3px #000;
  }

  & fieldset {
    border: none;
  }
`;

const StyledFormButton = styled(FormButton)`
  padding: 16.5px 14px;
`;

const AdminPage: FunctionComponent = (): ReactElement => {
  const [guestList, setGuestList] = useState<Guest[]>([]);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  useEffect(() => {
    const fetchGuests = async () => {
      api.getAllGuests().then((response) => {
        setGuestList(response);
      }).catch((error) => {
        console.error(error);
      });
    }

    fetchGuests();
  }, []);

  const handleAddGuest = async (): Promise<void> => {
    const newGuest = { firstname, lastname };

    await api.addNewGuest(newGuest)
      .then((response) => {
        setGuestList([...guestList, response]);

        setFirstname('');

        setLastname('');
      })
  };

  const handleDeletePerson = async (index: number): Promise<void> => {
    const guestId = guestList[index]._id;

    await api.deleteGuest(guestId!)
      .then(() => {
        const newGuest = [...guestList];

        newGuest.splice(index, 1);

        setGuestList(newGuest);
      });
  };

  // const handleEditPerson = (index: number, newFirstname: string, newLastname: string) => {
  //   const guestId = guestList[index]._id;
  //   const updatedPerson = { _id: guestId, firstname: newFirstname, lastname: newLastname };

  //   api.editExistingGuest(updatedPerson).then(() => {
  //     const newGuest = [ ...guestList ];

  //     newGuest[index] = updatedPerson;

  //     setGuestList(newGuest);
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // };

  return (
    <Container>
      <Grid
        container
        spacing={ 2 }
        flexDirection='column'
        alignItems='flex-start'
        marginBottom={ 3 }
      >
        <Grid item xs={ 12 }>
          <Headline
            variant="h1"
            align="left"
            gutterBottom
            textTransform='uppercase'
            width='100%'
          >
            Admin Page
          </Headline>
          <Typography
            variant="h5"
            align="left"
            gutterBottom
            textTransform='uppercase'
          >
            Add new guest
          </Typography>
          <InputFields
            required
            hiddenLabel
            id="firstname"
            name="firstname"
            placeholder='First name'
            autoComplete="first-name"
            value={ firstname }
            onChange={ (e) => setFirstname(e.target.value) }
          />
          <InputFields
            required
            hiddenLabel
            id="lastName"
            name="lastName"
            placeholder='Last Name'
            autoComplete="family-name"
            value={ lastname }
            onChange={(e) => setLastname(e.target.value)}
          />
          <StyledFormButton variant="contained" color="primary" onClick={ handleAddGuest }>
            Add Guest
          </StyledFormButton>
        </Grid>
        <Grid item xs={ 12 } sx={{ mt: 3 }}>
          <GuestListing
            guests={ guestList }
            onDelete={ handleDeletePerson }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminPage;
