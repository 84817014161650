import { Guest, GuestRegistration } from '../types/Guest';
import axios, { AxiosInstance } from 'axios';

class Api {
  private static instance: Api;
  private api: AxiosInstance;

  private constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}api`
    });
  }

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  async getAllGuests(): Promise<Guest[]> {
    const { data } = await this.api.get('/guests');

    return data;
  }

  async addMultipleNewGuests(guests: GuestRegistration[]): Promise<Guest[] | unknown> {
    try {
      const response = await this.api.post('/guestlist/add', { guests });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async addNewGuest(newGuest: GuestRegistration): Promise<Guest> {
    const response = await this.api.post('/guest/add', { ...newGuest });

    return response.data;
  }

  async getSingleGuest(query: Record<string, string>): Promise<{ isExistingGuest: boolean }> {
    const existingGuest = await this.api.post('/guest', { query });

    return existingGuest.data;
  }

  async editExistingGuest(query: Guest): Promise<{
    message: string;
    update: Guest;
  }> {
    const { _id, firstname, lastname } = query;
    const editExistingGuestResponse = await this.api.patch(`/guest/update/${_id}`, { query: { firstname, lastname } });

    return editExistingGuestResponse.data;
  }

  async deleteGuest(id: number): Promise<{
    message: string,
    deletedCount: number
  }> {
    return await this.api.delete(`/guest/delete/${id}`);
  }
}

export default Api.getInstance();