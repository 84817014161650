import { ExportToExcelButton } from './ExportToExcelButton';
import { Guest } from '../types/Guest';
import { GuestTable } from './GuestTable';
import { Box, Typography } from '@mui/material';
import React, { Fragment, FunctionComponent, ReactElement } from 'react';

interface GuestListingProps {
  guests: Guest[];
  onDelete: (index: number) => void;
}

const GuestListing: FunctionComponent<GuestListingProps> = ({ guests, onDelete }): ReactElement => {
  return (
    <Fragment>
      <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between'}}>
        <Typography
          variant="h5"
          align="left"
          gutterBottom
          textTransform='uppercase'
        >
          Guests
        </Typography>
        <ExportToExcelButton guests={ guests } />
      </Box>
      <GuestTable guests={ guests } onDelete={ onDelete } />
    </Fragment>
  )
};


export { GuestListing };