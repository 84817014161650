import { Button } from '@mui/material';
import styled from 'styled-components';

const FormButton = styled(Button)`
  box-shadow: none;
  border-radius: 0;
  background-color: #f7ab32;
  color: #000;
  font-weight: 600;
  border: solid 2px #000;

  &:hover {
    background-color: #c18524;
    box-shadow: none;
  }
`;

export { FormButton };