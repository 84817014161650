import styled from 'styled-components';
import { West as WestIcon } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { Fragment, FunctionComponent, ReactElement, SyntheticEvent } from 'react';


const BackButton = styled(Button)`
  color: #000;

  &:hover {
    background: none;
    color: #787882;
  }
`;

interface SuccessProps {
  prevStep: () => void;
};

const Success: FunctionComponent<SuccessProps> = ({ prevStep }): ReactElement => {
  const moveToPreviousStep = (event: SyntheticEvent): void => {
    event.preventDefault();

    prevStep();
  }

  return (
    <Fragment>
      <Typography component="h3" variant="h3">
        Thank you for your submission
      </Typography>
      <Typography component="p">You are now part of the adventure!</Typography>
      <Grid item xs={ 12 }>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', minHeight: '81px' }}>
          <BackButton
            variant="text"
            disableRipple={ true }
            onClick={ moveToPreviousStep }
          >
            <WestIcon fontSize='medium' sx={{ mr: 1 }} />
            Back
          </BackButton>
        </Box>
      </Grid>
    </Fragment>
  );
};

export { Success };