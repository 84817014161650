import AdminPage from './AdminPage';
import LoginPage from './LoginPage';
import { NoMatch } from './NoMatch';
import { CssBaseline, darkScrollbar } from '@mui/material';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Layout } from './Layout';
import { Home } from './Home';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          ...darkScrollbar(),
          backgroundColor: "#fff",
        }
      }
    }
  }
});

const App: FunctionComponent = (): ReactElement => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (username: string, password: string) => {

    const loginUser = process.env.REACT_APP_LOGIN_USER;
    const loginPassword = process.env.REACT_APP_LOGIN_PASSWORD;

    if (loginUser === username && loginPassword === password) {
      setIsAuthenticated(true);

      navigate('/admin');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/admin"
            element={isAuthenticated ? <AdminPage /> : <LoginPage onSubmit={ handleSubmit } />}
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export { App };
