import React, { FunctionComponent, ReactElement } from 'react';

const NoMatch: FunctionComponent = (): ReactElement => {
  return (
    <div>
      <h3>Nothing to see here!</h3>
    </div>
  );
};

export { NoMatch };