import { Button, Paper, TextField, Typography } from '@mui/material';
import React, { FormEvent, FunctionComponent, ReactElement, useState } from 'react';

interface LoginPageProps {
  onSubmit: (username: string, password: string) => void;
}

const LoginPage: FunctionComponent<LoginPageProps> = ({ onSubmit }): ReactElement => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit(username, password);
  }

  return (
    <div>
      <Paper elevation={ 3 }>
        <Typography variant="h5" component="h1" gutterBottom>
          Login
        </Typography>
        <form onSubmit={ handleFormSubmit }>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Log In
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default LoginPage;
